<template>
  <div class="forgetPassword">
    <div class="phoneInput" v-if="forgetType == 'phone'">
      <el-form :rules="phoneRules" ref="phoneForm" :model="passPhone">
        <el-form-item prop="phone">
          <el-input
              :placeholder="$t('enterTelephone')"
              v-model="passPhone.phone"
              class="input-with-select"
              type="number"
          >
            <el-select
                v-model="passPhone.areaCode"
                slot="prepend"
                popper-class="registerAreaCodeList"
                filterable
                :placeholder="$t('pleaseSelect')"
                :filter-method="areaCodeFilter"
            >
              <el-option
                  v-for="(item, index) in areaCodeList"
                  :key="item.area_code + index + ''"
                  :value="item.area_code"
                  :title="item.country | areacodeTitleFormat(LOCALE)"
              >
                <p class="area_code">{{ item.area_code }}</p>
                <p class="country textOverflow">
                  {{ item.country | areacodeAddressFormat(LOCALE) }}
                </p>
              </el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input
              style="width: 60%"
              :placeholder="$t('enterVCode')"
              type="text"
              v-model="passPhone.code"
              autocomplete="off"
              nowritten
          ></el-input>
          <el-button
              class="fr getCodeBtn"
              @click="getCode"
              :disabled="codeBtnDisabled"
          >
            {{ codeText ? codeText : $t("getvCode") }}
            <span v-if="parseInt(codeText) == codeText">&nbsp;s</span>
          </el-button>
          <div class="custom_error_tip" v-show="phoneErrorShow">
            {{ $t("invalidphoneAccount") }}
          </div>
          <div class="custom_error_tip" v-show="phoneDisabledShow">
            {{ $t("accountDisabledTip1") }}
          </div>
        </el-form-item>
        <div class="lookEmail cursor fr">
          <slot name="followEmail"></slot>
        </div>
      </el-form>
    </div>
    <div class="emailInput" v-else>
      <el-form
          :label-position="'top'"
          :model="emailForm"
          ref="emailForm"
          :rules="emailRules"
      >
        <el-form-item prop="email">
          <el-input
              v-model="emailForm.email"
              size="medium"
              nowritten
              :placeholder="$t('enterEmail')"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="footerBtn" @click="resetChange">
      <slot name="footerBtn"></slot>
    </div>
  </div>
</template>
<script>
/**
 * 插槽:
 * footerBtn 更改密码按钮插槽（在父组件不用再写登录接口，只需要写样式文字和loading）
 * followEmail: 邮箱找回插槽 在父组件没有用到tabs,就可以用此插槽
 * 方法：
 * codeSuccess：修改密码验证成功(电话)
 * emailSuccess:修改密码验证成功(邮箱)
 *
 */
import {areaCodeList} from "~/baseUtils/AddressConfig";
import {SSstore} from "~/baseUtils/storage";
import {constName} from "~/baseUtils/constName";
import {confirmEmailLogin} from "~/baseUtils";
import {validateTelephone, validateEmail} from "~/baseUtils/validate";
import EmailLogin from "~/baseComponents/EmailLogin";

export default {
  components: {
    EmailLogin,
  },
  props: {
    forgetType: {
      type: String,
      default: "phone",
    },
  },
  data() {
    return {
      areaCodeList,
      areaCodeListCopy: [].concat(areaCodeList),
      passPhone: {
        phone: "",
        areaCode: "+86",
        code: "",
      },
      emailForm: {
        email: "",
      },
      codeText: "",
      codeBtnDisabled: false,
      phoneDisabledShow: false,
      phoneErrorShow: false,
      forgetLoading: false,
      forgetEmailLoading: false,
      showForm: true,
      isPassWordShow: 1,
      user_id: "",
      phoneRules: {
        phone: {
          validator: async (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else if (!validateTelephone(value)) {
              this.codeBtnDisabled = true;
              callback(new Error(this.$t("wrongTelephoneFormat")));
            } else {
              try {
                let result
                if (this.PJID === 'YMP') {
                  result = await this.$store.dispatch(
                      "baseStore/baseSign_checkIdentifier",
                      {identifier: `${value}`, type: 2}
                  );
                } else {
                  result = await this.$store.dispatch(
                      "baseStore/baseSign_checkIdentifier",
                      {identifier: `+86${value}`, type: 2}
                  );
                }
                if (result.success) {
                  this.codeBtnDisabled = true;
                  callback(new Error(this.$t("telephoneNotReg")));
                } else {
                  this.codeBtnDisabled = false;
                  this.user_id = result.user_id;
                  callback();
                }
              } catch (e) {
                callback(new Error(" "));
                this.$seTip();
                console.log(e);
              }
            }
          },
          trigger: "blur",
        },
        code: {
          validator: async (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else {
              if (this.checkCode) {
                let result = await this.$store.dispatch(
                    "baseStore/checkVerifyCode",
                    {
                      area_code: this.passPhone.areaCode.replace("+", ""),
                      mobile: `${this.passPhone.phone}`,
                      ctype: 2,
                      code: this.passPhone.code,
                    }
                );
                this.checkCode = false;
                this.forgetLoading = false;
                if (result.success) {
                  if (result.data) {
                    SSstore.set("GLOBALRESETUID", this.user_id);
                    this.$emit("codeSuccess");
                    callback();
                  } else {
                    this.checkCode = false;
                    callback(new Error(this.$t("errorVerifyCode")));
                  }
                } else {
                  this.checkCode = false;
                  callback(new Error(" "));
                  this.$seTip();
                }
              } else {
                callback();
              }
            }
          },
          trigger: "blur",
        },
      },
      emailRules: {
        email: {
          validator: async (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else if (!validateEmail(value)) {
              callback(new Error(this.$t("wrongEmailFormat")));
            } else {
              try {
                let result = await this.$store.dispatch(
                    "baseStore/baseSign_checkIdentifier",
                    {identifier: value, type: 1}
                );
                if (result.success) {
                  callback(new Error(this.$t("emailNotReg")));
                } else {
                  callback();
                }
              } catch (e) {
                callback(new Error(" "));
                this.$seTip();
              }
            }
          },
          trigger: "blur",
        },
      },
    };
  },
  methods: {
    areaCodeFilter(value) {
      if (value) {
        this.areaCodeList = this.areaCodeListCopy
            .filter((item) => {
              return item.area_code.indexOf(value) == 1;
            })
            .sort((a, b) => {
              return parseInt(a.area_code) - parseInt(b.area_code);
            });
      } else {
        this.areaCodeList = this.areaCodeListCopy;
      }
    },
    resetChange() {
      if (this.forgetType == "email") {
        this.goResetEmail();
      } else {
        this.checkCode = true;
        this.$refs.phoneForm.validate(async (valid) => {
          if (valid) {
          }
        });
      }
    },
    async getCode() {
      if (this.passPhone.phone == "") {
        this.$message.warning(this.$t("enterTelephone"));
        return;
      }
      try {
        let result = await this.$store.dispatch("baseStore/getVerifyCode", {
          area_code: this.passPhone.areaCode.replace("+", ""),
          mobile: `${this.passPhone.phone}`,
          ctype: 2,
        });
        if (result.success) {
          this.$message.success(this.$t("vcodeSendSuccess"));
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.codeText = TIME_COUNT;
            this.codeBtnDisabled = true;
            this.timer = setInterval(() => {
              if (this.codeText > 0 && this.codeText <= TIME_COUNT) {
                this.codeText--;
              } else {
                this.codeText = this.$t("getvCode");
                clearInterval(this.timer);
                this.timer = null;
                this.codeBtnDisabled = false;
              }
            }, 1000);
          }
        } else {
          this.$seTip();
        }
      } catch (e) {
        console.log(e);
        this.$seTip();
      }
    },
    goResetEmail() {
      this.$refs.emailForm.validate(async (valid) => {
        if (valid) {
          this.$emit("getLoading", true);
          this.forgetEmailLoading = true;
          try {
            let result = await this.$store.dispatch(
                "baseStore/baseSign_Forget",
                {
                  email: this.emailForm.email,
                  domain_name_source: this.LOCALE == "en" ? 2 : 1
                }
            );
            this.forgetEmailLoading = false;
            if (result.success) {
              //   this.showForm = false;
              this.$emit("emailSuccess", this.emailForm.email);
            } else {
              this.$seTip();
            }
          } catch (e) {
            this.forgetLoading = false;
            this.$seTip();
          }
          this.$emit("getLoading", false);
        }
      });
    },
    followEmail() {
      this.$emit("followEmail");
    },
  },
};
</script>
<style lang="less" scoped>
.getCodeBtn {
  width: 38%;
}
</style>