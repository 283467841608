<template>
  <div class="password">
    <div class="page">
      <div class="tc title">
        <i class="el-icon-back iconBack cursor" @click="back"></i
        >{{ $t("forgetPassword") }}
      </div>
      <div v-show="!isFollow">
        <forgetPassword @codeSuccess="codeSuccess">
<!--          <span slot="followEmail" class="mainColor" @click="emailFollow">{{-->
<!--            $t("followEmail")-->
<!--          }}</span>-->
          <el-button
            slot="footerBtn"
            type="primary"
            class="loginBut marT"
            size="small"
            >{{ $t("confirm") }}</el-button
          >
        </forgetPassword>
      </div>
      <div v-show="isFollow">
        <div v-if="!isEmailSend">
          <forgetPassword
            @emailSuccess="emailSuccess"
            :forgetType="'email'"
            @getLoading="btnLoadingChange"
          >
            <el-button
              slot="footerBtn"
              type="primary"
              class="loginBut"
              size="small"
              :loading="emailLoading"
              >{{ $t("confirm") }}</el-button
            >
          </forgetPassword>
        </div>
        <div class="active-tip" v-else>
          <div class="clearfix">
            <p class="tip_header fl">{{ $t("resetLinkTip1") }}</p>
            <EmailLogin :email="forgetEmail" :color="'#027fff'"></EmailLogin>
          </div>

          <p class="small">
            <span class="dangerColor">*</span>
            {{ $t("activeLinkTip2") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import forgetPassword from "~/baseComponents/baseSign/forgetPassword";
import EmailLogin from "~/baseComponents/EmailLogin";

export default {
  components: { forgetPassword, EmailLogin },
  data() {
    return {
      isFollow: false,
      isEmailSend: false,
      forgetEmail: "",
      emailLoading: false,
    };
  },
  mounted() {
    if (this.IS_EN) {
      this.isFollow = true;
    } else {
      this.isFollow = false;
    }
  },
  methods: {
    codeSuccess() {
      this.$router.push("/resetPassword");
    },
    emailSuccess(email) {
      this.forgetEmail = email;
      this.isEmailSend = true;
    },
    btnLoadingChange(val) {
      this.emailLoading = val;
    },
    back() {
      if (this.isFollow) {
        if (!this.IS_EN) {
          this.isFollow = false;
        } else {
          this.$router.push("/sign");
        }
      } else {
        this.$router.push("/sign");
      }
    },
    emailFollow() {
      this.isFollow = true;
    },
  },
};
</script>
<style lang="less" scoped>
.password {
  padding: 100px 0;
  min-height: 84vh;
  .page {
    width: 400px;
    margin: 0 auto;
    .title {
      font-size: 21px;
      font-weight: bold;
      margin-bottom: 30px;
      .iconBack {
        border: 1px solid #7d8695;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
    .loginBut {
      width: 100%;
      display: block;
      height: 40px;
      margin: 24px auto 12px;
      border: 0 none;
      font-size: 16px;
      background: #91BE42!important;
    }
  }
  .active-tip {
    padding-top: 30px;
  }
  .tip_header {
    margin: 2px 5px 10px 0;
  }
  .dangerColor {
    color: #027fff;
  }
  .marT {
    margin: 50px auto 12px !important;
  }
}
</style>